import React from "react";
import Manoush from "../../images/menuImage/Manoush.jpeg";
import manoushPhoto from "../../images/menuImage/manoushPhoto.png";
import DefaultLayout from "../Layout/DefaultLayout";
import "./manoushPage.css";

import { Menu } from "../menu/Menu";

export const ManoushPage = () => {
  return (
    <DefaultLayout>
      <img className="manoushPhoto" src={manoushPhoto} alt="" />
      <div className="coffeePage" style={{ marginTop: "50px" }}>
        <img src={Manoush} alt="" width="90%" className="d-block m-auto" />
        <Menu />
      </div>
    </DefaultLayout>
  );
};
