import React from "react";
import "./hero.css";
import heroPic from "../../images/heroPic.jpg";
import free from "../../images/free.jpeg";
import { Carousel } from "react-bootstrap";

export const Hero = () => {
  return (
    <div className="hero bg-dark" id="hero" style={{ marginButton: "10%" }}>
      <Carousel>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100 " src={free} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img className="d-block w-100" src={heroPic} alt="Second slide" />
          <Carousel.Caption>
            <div className="brand fw-bold" style={{ color: "white" }}>
              Soul Mill
            </div>
            <div className="brand-info fw-bolder " style={{ color: "white" }}>
              Manoush · OvenBurgers & Wraps · Pizza
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
