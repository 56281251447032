import React from "react";
import {
  Button,
  Container,
  Image,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { Router } from "react-router-dom";
import logo from "../../images/logo.jpeg";
import { Link } from "react-router-dom";
import "./customNavbar.css";

export const CustomNavbar = () => {
  return (
    <Navbar collapseOnSelect expand="lg" variant="dark" className="nav-bar">
      <Container>
        <Link to="/">
          <img src={logo} alt="Logo image" className="header__logo" />
        </Link>

        <a
          href="tel:(02) 8580 2945"
          style={{ color: "white", textDecorationLine: "none" }}
          className=" header__number ms-2 order-lg-2 "
        >
          (02) 8580 2945
        </a>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto nav__bar">
            <Link to="/" className="nav-link fw-bold">
              {/* <Nav.Link href="#features">HOME</Nav.Link> */}
              HOME
            </Link>
            <Link to="/main-menu-page" className="nav-link fw-bold">
              {/* <Nav.Link href="#features">HOME</Nav.Link> */}
              MENU
            </Link>
            <Link to="/aboutPage" className="nav-link fw-bold">
              {/* <Nav.Link href="#features">HOME</Nav.Link> */}
              {/* <Nav.Link href="#pricing">MENU</Nav.Link> */}
              {/* <Nav.Link href="#pricing">ABOUT</Nav.Link> */}
              ABOUT
            </Link>
            <Link to="/contactPage" className="nav-link fw-bold">
              {/* <Nav.Link href="#features">HOME</Nav.Link> */}
              {/* <Nav.Link href="#pricing">CONTACT</Nav.Link> */}
              CONTACT
            </Link>
          </Nav>
          <Nav>
            {/* <Nav.Link className="text-light header__number mt-2">
              Tel: (02) 8580 2945
            </Nav.Link> */}

            <Nav.Link size="sm">
              <Link to="/orderonline" className="nav__button">
                <Button className="header__btn">Order Online</Button>
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
