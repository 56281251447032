import React from "react";
import { Hero } from "../hero/Hero";
import { About } from "../about/About";
import { Menu } from "../menu/Menu";
import { Email } from "../email/Email";
import DefaultLayout from "../Layout/DefaultLayout";

export const MainPage = () => {
  return (
    <DefaultLayout>
      <div className="mainPage">
        <Hero />
        <Menu />
        <About />
        <Email />
      </div>
    </DefaultLayout>
  );
};
