import React from "react";
import { useState } from "react";

import "./mainMenuPage.css";
import menuPhoto from "../../images/menuImage/menuPhoto.png";
import ovenBurgers from "../../images/menuImage/ovenBurgers.png";
import ovenWraps from "../../images/menuImage/ovenWraps.jpeg";
import Manoush from "../../images/menuImage/Manoush.jpeg";
import pizza from "../../images/menuImage/pizza.jpeg";
import salads from "../../images/menuImage/Salads.jpg";
import { Link } from "react-router-dom";
import { Menu } from "../menu/Menu";
import DefaultLayout from "../Layout/DefaultLayout";

const imageMenu = [
  {
    id: 1,
    image: ovenWraps,
  },
  {
    id: 2,
    image: ovenBurgers,
  },
  {
    id: 3,
    image: salads,
  },
  {
    id: 4,
    image: Manoush,
  },
  {
    id: 5,
    image: pizza,
  },
];

export const MainMenuPage = () => {
  const [display, setDisplay] = useState(imageMenu);

  const handelOnOvenWraps = (id) => {
    const filter = imageMenu.filter((id, elm) => imageMenu[0] === id);
    setDisplay(filter);
  };

  const handelOnOvenBurgers = (id) => {
    const filter = imageMenu.filter((id, elm) => imageMenu[1] === id);
    setDisplay(filter);
  };

  const handelOnSalads = (id) => {
    const filter = imageMenu.filter((id, elm) => imageMenu[2] === id);
    setDisplay(filter);
  };

  const handelOnManoush = (id) => {
    const filter = imageMenu.filter((id, elm) => imageMenu[3] === id);
    setDisplay(filter);
  };

  const handelOnPizza = (id) => {
    const filter = imageMenu.filter((id, elm) => imageMenu[4] === id);
    setDisplay(filter);
  };

  return (
    <DefaultLayout>
      <img className="menuImg" src={menuPhoto} alt="" />

      <div className="mainMenu">
        <h1 className="heading__menu text-dark">Our Menu</h1>
        <div className="name__menu ">
          <button onClick={() => handelOnManoush(4)}>Manoush</button>
          <button onClick={() => handelOnOvenWraps(1)}>Oven Wraps</button>
          <button onClick={() => handelOnOvenBurgers(2)}>Oven Burgers</button>
          <button onClick={() => handelOnSalads(3)}>Salads</button>
          <button onClick={() => handelOnPizza(5)}>Pizza</button>
        </div>

        {display.map((img, i) => {
          const { id, image } = img;
          return <img src={image} alt="" key={id} className="image__menu " />;
        })}
        <Menu />
      </div>
    </DefaultLayout>
  );
};
