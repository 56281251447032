import { Container } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { About } from "./components/about/About";
import { CustomNavbar } from "./components/Layout/CustomNavbar.js";
import { Email } from "./components/email/Email";
import { Footer } from "./components/Layout/Footer";
import { Hero } from "./components/hero/Hero";
import { Menu } from "./components/menu/Menu";
import DefaultLayout from "./components/Layout/DefaultLayout";
import { AboutPage } from "./components/aboutPage/AboutPage";
import { MainPage } from "./components/mainPage/MainPage";
import ContactPage from "./components/contactPage/ContactPage";
import { MainMenuPage } from "./components/menuPage/MainMenuPage";
import { CoffeePage } from "./components/menuPage/CoffeePage";
import { OrderOnline } from "./components/Order/OrderOnline";
import { ManoushPage } from "./components/menuPage/ManoushPage";

function App() {
  return (
    <div className="wrapper">
      <Routes>
        <Route path="Menu" element={<Menu />} />
        <Route path="aboutPage" element={<AboutPage />} />
        <Route path="contactPage" element={<ContactPage />} />
        <Route path="main-menu-page" element={<MainMenuPage />} />
        <Route path="coffee-page" element={<CoffeePage />} />
        <Route path="manoush-page" element={<ManoushPage />} />
        <Route path="orderonline" element={<OrderOnline />} />
        <Route path="/" element={<MainPage />} />
      </Routes>
    </div>
  );
}

export default App;
