import React from "react";
import "./orderOnline.css";
import { Card, Button, Col, Row } from "react-bootstrap";
import uberEats from "../../orderImages/uberEats.jpeg";
import menuLog from "../../orderImages/menuLog.jpeg";
import Doordash from "../../orderImages/Doordash.jpeg";
import bread2 from "../../images/aboutbread/bread2.jpeg";
import { Menu } from "../menu/Menu";
import DefaultLayout from "../Layout/DefaultLayout";

export const OrderOnline = () => {
  return (
    <DefaultLayout>
      <div className="order__hero container ">
        <Card className="card__card uber">
          <Card.Img
            variant="top"
            className="uberEatsPhoto photo"
            src={uberEats}
          />
          <Card.Body>
            <Card.Text className="text">
              Order Our Food from UberEats and Enjoy with your friends and
              family.
            </Card.Text>

            <Button
              href="https://www.ubereats.com/store/soul-mill/GiWLDzHhQY6mzrFvkRnAHQ?diningMode=DELIVERY"
              target="__blank"
              variant="dark"
            >
              Order
            </Button>
          </Card.Body>
        </Card>

        <Card className="card__card menuLog">
          <Card.Img variant="top " className="photo" src={menuLog} />
          <Card.Body>
            <Card.Text className="text">
              Order Our Food from Menulog and Enjoy with your friends and
              family.
            </Card.Text>
            <Button
              href="https://www.menulog.com.au/restaurants-soul-mill/menu"
              target="__blank"
              variant="dark"
            >
              Order
            </Button>
          </Card.Body>
        </Card>

        <Card className="card__card">
          <Card.Img
            variant="top"
            className="doorDashPhoto photo"
            src={Doordash}
          />
          <Card.Body>
            <Card.Text className="text">
              Order Our Food from Doordash and Enjoy with your friends and
              family.
            </Card.Text>
            <Button
              href="https://www.doordash.com/store/soul-mill-rockdale-1355171/?pickup=true"
              target="__blank"
              variant="dark"
            >
              Order
            </Button>
          </Card.Body>
        </Card>
      </div>
    </DefaultLayout>
  );
};
