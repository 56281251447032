import React from "react";
import { useState } from "react";
// import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { receiveEmail } from "../../helper/axiosHelper";

import DefaultLayout from "../Layout/DefaultLayout";

const initialState = { name: "", email: "", phone: "", message: "" };
export const Email = () => {
  // const [fristName, setFristName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  // const [comment, setComment] = useState("");
  const [form, setForm] = useState(initialState);

  const handelOnChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    receiveEmail(form);
    setForm(initialState);
    alert("Thanks for ordering with us 😀  Order submitted");
  };
  return (
    <div style={{ backgroundColor: "lightgrey" }}>
      <div
        className="email pb-5 m-auto "
        id="email"
        style={{ maxWidth: "1000px" }}
      >
        <h1 className="text-center pt-5 fw-bolder">Email Us</h1>

        <Form style={{ padding: "4% 10% 0% 10%" }} onSubmit={sendEmail}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={form.name}
                  size="sm"
                  onChange={handelOnChange}
                  required
                  name="name"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  size="sm"
                  value={form.email}
                  required
                  onChange={handelOnChange}
                  name="email"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  size="sm"
                  value={form.phone}
                  required
                  onChange={handelOnChange}
                  name="phone"
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="fw-bold">
              You can send us an Email to order breakfast/ lunch 🍕!{" "}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              size="sm"
              required
              value={form.message}
              name="message"
              onChange={handelOnChange}
              placeholder="Tells us your Order and PickUp time..."
            />
          </Form.Group>
          <Button
            variant="dark"
            size="sm"
            style={{ fontSize: "14px" }}
            type="submit"
            value="send"
          >
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};
