import React from "react";
import coffeeMenu from "../../images/menuImage/coffeeMenu.jpg";
import JuiceMenu from "../../images/menuImage/JuiceMenu.jpeg";
import DefaultLayout from "../Layout/DefaultLayout";
import { Menu } from "../menu/Menu";

export const CoffeePage = () => {
  return (
    <DefaultLayout>
      <div className="coffeePage" style={{ marginTop: "80px" }}>
        <img src={coffeeMenu} alt="" width="90%" className="d-block m-auto" />
        <img src={JuiceMenu} alt="" width="90%" className="d-block m-auto" />
        <Menu />
      </div>
    </DefaultLayout>
  );
};
