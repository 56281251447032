import React from "react";
import "./aboutPage.css";
import bread1 from "../../images/aboutbread/bread1.jpeg";
import bread2 from "../../images/aboutbread/bread2.jpeg";
import bread3 from "../../images/aboutbread/bread3.jpeg";
import bread4 from "../../images/aboutbread/bread4.jpeg";
import bread5 from "../../images/aboutbread/bread5.jpeg";
import bread6 from "../../images/aboutbread/bread6.jpeg";
import coffee from "../../images/aboutCoffee/coffee.jpeg";
import coffee1 from "../../images/aboutCoffee/coffee1.jpeg";
import coffee2 from "../../images/aboutCoffee/coffee2.jpeg";
import coffee3 from "../../images/aboutCoffee/coffee3.jpeg";
import coffee4 from "../../images/aboutCoffee/coffee4.jpeg";
import coffee5 from "../../images/aboutCoffee/coffee5.jpeg";
import coffee6 from "../../images/aboutCoffee/coffee6.jpeg";
import coffeeBrand from "../../images/aboutCoffee/coffeeBrand.png";
import pizza1 from "../../images/aboutPizza/pizza1.jpeg";
import pizza2 from "../../images/aboutPizza/pizza2.jpeg";
import pizza3 from "../../images/aboutPizza/pizza3.jpeg";
import pizza4 from "../../images/aboutPizza/pizza4.jpeg";
import pizza5 from "../../images/aboutPizza/pizza5.jpeg";
import pizza6 from "../../images/aboutPizza/pizza6.jpeg";
import food1 from "../../images/aboutFood/food1.jpeg";
import food2 from "../../images/aboutFood/food2.jpeg";
import food3 from "../../images/aboutFood/food3.jpeg";
import food4 from "../../images/aboutFood/food4.jpeg";
import food5 from "../../images/aboutFood/food5.jpeg";
import area1 from "../../images/aboutArea/area1.jpeg";
import area2 from "../../images/aboutArea/area2.jpeg";
import area3 from "../../images/aboutArea/area3.jpeg";
import area4 from "../../images/aboutArea/area4.jpeg";
import { Col, Container, Image, Row } from "react-bootstrap";
import DefaultLayout from "../Layout/DefaultLayout";

export const AboutPage = () => {
  let images = [
    {
      id: 1,
      imgSrc: bread1,
    },
    {
      id: 2,
      imgSrc: bread2,
    },
    {
      id: 3,
      imgSrc: coffee,
    },
    {
      id: 4,
      imgSrc: bread4,
    },
    {
      id: 5,
      imgSrc: bread5,
    },
    {
      id: 6,
      imgSrc: bread6,
    },
    {
      id: 7,
      imgSrc: coffee1,
    },
    {
      id: 8,
      imgSrc: coffee2,
    },
    {
      id: 23,
      imgSrc: food5,
    },
    {
      id: 9,
      imgSrc: coffee3,
    },
    {
      id: 10,
      imgSrc: coffee4,
    },
    {
      id: 11,
      imgSrc: coffee5,
    },
    {
      id: 12,
      imgSrc: coffee6,
    },
    {
      id: 13,
      imgSrc: pizza1,
    },
    {
      id: 14,
      imgSrc: pizza2,
    },
    {
      id: 15,
      imgSrc: pizza3,
    },
    {
      id: 16,
      imgSrc: pizza4,
    },
    {
      id: 17,
      imgSrc: pizza5,
    },
    {
      id: 18,
      imgSrc: pizza6,
    },
    {
      id: 19,
      imgSrc: food1,
    },
    {
      id: 20,
      imgSrc: food2,
    },
    {
      id: 21,
      imgSrc: food3,
    },
    {
      id: 22,
      imgSrc: food4,
    },
    {
      id: 23,
      imgSrc: bread1,
    },
  ];

  return (
    <DefaultLayout>
      <div id="aboutPage" style={{ marginTop: "80px" }}>
        <Container>
          <h1 className="text-center fw-bold">About Soul Mill</h1>
          <p style={{ whiteSpace: "pre-line", textAlign: "center" }}>
            Out and about in Rockdale and looking for affordable gourmet? Look
            no further than Soul Mill!
          </p>
          <Row className=" mt-5">
            <Col sm="12" md="4">
              <img
                src={area1}
                alt=""
                style={{ width: "100%", padding: "5%" }}
                className="d-block m-auto"
              />
            </Col>
            <Col sm="12" md="4">
              <img
                src={area2}
                alt=""
                style={{ width: "100%", padding: "5%" }}
                className="d-block m-auto"
              />
            </Col>
            <Col sm="12" md="4">
              <img
                src={area3}
                alt=""
                style={{ width: "100%", padding: "4%" }}
                className="d-block m-auto"
              />
            </Col>
          </Row>

          <h1 className="text-center mt-5">Our Food</h1>
          <p style={{ textAlign: "center" }}>
            Walk in for our famous OvenBurgers, Artisanal Coffee, Manoush, Wraps
            & Pizza or drop us an order via phone or email!
          </p>
          <div className="gallery">
            {images.map((item, index) => {
              return (
                <div className="pics" key={index}>
                  <img
                    src={item.imgSrc}
                    style={{ width: "100%", marginBottom: "10px" }}
                  />
                </div>
              );
            })}
          </div>

          <Row style={{ padding: "5% 3% 5% 3%" }}>
            <h1 className="proj-title fw-bolder text-center mb-5">
              Rockdale Community
            </h1>

            <Col md="6" lg="6">
              <Image
                src={area4}
                alt="project "
                className=" pj-img img-fluid d-block m-auto"
                style={{ width: "100%" }}
              />
            </Col>

            <Col md="6" lg="6" className=" ">
              <p className="rockdale-info">
                King Street in Rockdale is an amazing life affair in itself.
                Come down here in the mornings and behold stories unfold around
                you.
                <br />
                <br />
                The camaraderie of the people who come out for coffee and enjoy
                the morning sun are a testimony to Australian lifestyle acquired
                by migrants over the years.
                <br />
                <br />
                They are our regulars: they talk, they laugh, they smoke*, they
                sip and make this precinct a beautiful village. They bring an
                incredible vibe to this surrounding. Everybody here seems to
                know everybody.
                <br />
                <br />
                *Smoking is hazardous to health.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </DefaultLayout>
  );
};
