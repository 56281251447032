import axios from "axios";

const emailApi = 'https://email-server-for-soul-mill.herokuapp.com/api/v1/email'

export const receiveEmail = async (email) => {
  try {
    const { data } = await axios.post(emailApi, email);
  } catch (error) {
    return {
      status: "error",
      message: error.message,
    };
  }
};
