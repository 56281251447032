import React from "react";
import "./about.css";
import { Button, Col, Container, Image, Row } from "react-bootstrap";

import aboutPic from "../../images/aboutPic.png";
import aboutPic2 from "../../images/aboutPic2.jpg";
import ovenAbout from "../../images/ovenAbout.jpg";
import coffeeAbout from "../../images/coffeeAbout.jpeg";
import DefaultLayout from "../Layout/DefaultLayout";

export const About = () => {
  return (
    <div style={{ marginTop: "60px" }}>
      {" "}
      <img className="d-block w-100 " src={aboutPic} alt="aboutPic" />
      <Container id="about">
        <h1 className="text-center mt-5 fw-bolder">About Us</h1>
        <p style={{ textAlign: "center" }}>
          Out and about in Rockdale and looking for affordable gourmet? Look no
          further than Soul Mill!
        </p>
        <Row style={{ padding: "5% 3% 0% 3%" }}>
          <Col md="6">
            <Image src={ovenAbout} alt="project " className=" pj-img  " />
          </Col>

          <Col md="6" className="about-info">
            <h2 className=" fw-bolder title">About Our Oven</h2>

            <p className="mt-3">
              All pizzas and Manoush are freshly cooked in our oven! Check out
              pizzas, Manoush and oven wraps menu for either takeaway or
              dine-in!
            </p>
            <Button href="/aboutPage" className="bg-dark border-dark mt-3">
              Read More
            </Button>
          </Col>
        </Row>
        <Row className="mt-5" style={{ padding: "5% 4% 10% 4%" }}>
          <Col md="6" className="order-md-2">
            <Image
              src={coffeeAbout}
              alt="project "
              className=" pj-img d-block ms-auto "
            />
          </Col>{" "}
          <Col md="6" className="about-info">
            <h2 className=" fw-bolder title"> Artisanal Coffee</h2>

            <p className="mt-3">
              Nothing can compare with a strong creamy coffee to open your
              senses in the morning! Soul Mill flavoursome and aromatic coffee
              is a great choice to start your day, and we use vittoria coffee
              beans!
            </p>
            <Button href="/aboutPage" className="bg-dark border-dark mt-3">
              Read More
            </Button>
          </Col>
        </Row>
      </Container>
      <img className="d-block w-100 " src={aboutPic2} alt="aboutPic2" />
    </div>
  );
};
