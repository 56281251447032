import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import coffeeDrinks from "../../images/coffeeDrinks.jpeg";
import mainMenu from "../../images/mainMenu.jpeg";
import manoushMini from "../../images/manoushMini.jpg";
import DefaultLayout from "../Layout/DefaultLayout";

export const Menu = () => {
  return (
    <div style={{ paddingTop: "10%", paddingBottom: "5%" }} id="menu">
      <h1 className="text-center fw-bolder">Soul Mill Menu</h1>
      <Container>
        <Row className=" ">
          <Col
            md="4"
            className="d-flex mt-5"
            style={{
              flexDirection: "column",
            }}
          >
            <img src={mainMenu} alt="" width="60%" className="d-block m-auto" />
            <Link to="/main-menu-page" className="nav-link">
              <Button
                onClick={() => window.scrollTo(0, 0)}
                variant="dark"
                className="fw-bold mt-5 d-block m-auto "
                // style={{ width: "80%" }}
              >
                Main Menu
              </Button>
            </Link>
          </Col>
          <Col
            md="4"
            className="d-flex mt-5"
            style={{
              flexDirection: "column",
            }}
          >
            <img
              src={manoushMini}
              alt=""
              width="60%"
              className="d-block m-auto"
            />
            <Link to="/manoush-page" className="nav-link">
              <Button
                onClick={() => window.scrollTo(0, 0)}
                variant="dark"
                className="fw-bold mt-5 d-block m-auto "
                // style={{ width: "80%" }}
              >
                Manoush
              </Button>
            </Link>
          </Col>

          <Col
            md="4"
            className="d-flex"
            style={{
              flexDirection: "column",
            }}
          >
            <img
              src={coffeeDrinks}
              alt=""
              width="62%"
              className="d-block m-auto mt-5"
            />
            <Link to="/coffee-page" className="nav-link ">
              <Button
                onClick={() => window.scrollTo(0, 0)}
                variant="dark"
                className="fw-bold mt-5 d-block m-auto coffee-btn"
                // style={{ width: "80%" }}
              >
                <span>Coffee& Juice</span>
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
