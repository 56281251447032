import React, { useEffect } from "react";
// import { MainPage } from "../mainPage/MainPage";
import { CustomNavbar } from "./CustomNavbar";
import { Footer } from "./Footer";

const DefaultLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="layout">
      <CustomNavbar />

      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
