import React from "react";
import { Email } from "../email/Email";
import DefaultLayout from "../Layout/DefaultLayout";

const ContactPage = () => {
  return (
    <div className="contactPage " style={{ marginButton: "10%" }}>
      <DefaultLayout>
        <Email />
      </DefaultLayout>
    </div>
  );
};

export default ContactPage;
