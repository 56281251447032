import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "./footer.css";

export const Footer = () => {
  return (
    <div className="text-light pb-4 " style={{ backgroundColor: "black" }}>
      <h1 className="text-center pt-5 fw-bolder">Find Us</h1>
      <p className="text-center ">Our location is in the heart of Rockdale. </p>

      <Container>
        <Row className="mt-2 bg-light m-auto find-us-row ">
          <Col className="contact-info text-dark px-4 find-us-info mt-4 ">
            <div style={{ textAlign: "left" }}>
              <div>
                <div className="fs-6 fw-bolder ">
                  {" "}
                  <i class="fa-solid fa-location-dot"></i> Location
                </div>
                <p> 9 King St Rockdale, NSW 2216 </p>
              </div>

              <div>
                <div className="fs-6 fw-bolder mt-3">
                  {" "}
                  <i class="fa-solid fa-phone"></i>Tel
                </div>
                <p> (02) 8580 2945 </p>
              </div>

              <div className="fs-6 fw-bolder mt-3">Opening hours</div>
              <p style={{ whiteSpace: "pre-line" }}>
                Monday to Thursday - 5:30 am to 5pm <br />
                Friday - 5:30 am to 7:30 pm <br />
                Saturday - 6am to 7:30 pm <br />
                Sunday - 6am to 4pm <br />
              </p>
            </div>
          </Col>

          <Col>
            <iframe
              className="d-block m-auto p-3"
              width="320"
              height="320"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              id="gmap_canvas"
              src="https://maps.google.com/maps?width=500&amp;height=300&amp;hl=en&amp;q=9%20King%20St%20Rockdale+(SoulMill)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>{" "}
            <script
              type="text/javascript"
              src="https://embedmaps.com/google-maps-authorization/script.js?id=62592f5b590da2382b5317cb27ef85441bd73556"
            ></script>
          </Col>
        </Row>

        <Row className="p-3">
          <Col className="d-flex justify-content-center fs-3">
            <a
              target="_blank"
              href="https://www.instagram.com/soulmill_/?hl=en"
            >
              {/* <span className="fab fa-linkedin text-light p-3"></span> */}

              <span className="fa-brands fa-instagram p-3 text-light"></span>
            </a>

            <a target="_blank" href="https://www.facebook.com/SoulMil/">
              <span className="fab fa-facebook-square text-light p-3"></span>
            </a>
          </Col>
        </Row>
        <p className="text-center copy-right">
          Copyright © 2023 All rights reserved Soul Mill
        </p>
      </Container>
    </div>
  );
};
